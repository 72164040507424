<template>
  <div>
    <div>
      <van-panel>
        <div slot="header"
             class="tc pv-30">
          <van-icon name="checked"
                    size="98px"
                    class="success" />
          <div class="fs120 mt-15">支付成功</div>
        </div>
        <div slot="footer">
          <van-button type="primary"
                      block
                      class="mb-20">查看订单</van-button>
        </div>
      </van-panel>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Panel, Button } from 'vant'
Vue.use(Panel).use(Button);

export default {
  name: 'done',
  data () {
    return {

    }
  },
  methods: {

  },
  created () {

  }
}
</script>

<style>
</style>